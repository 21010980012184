import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  filterCount: {
    active: 0,
    draft: 0,
    archived: 0,
  },
  active: {
    data: [],
    totalAmount: 0,
  },
  draft: {
    data: [],
    totalAmount: 0,
  },
  archived: {data: [], totalAmount: 0},
  isLoading: false,
};

export const myPositionsSlice = createSlice({
  name: 'myPositionsSlice',
  initialState,
  reducers: {
    setPositionsListAction: (state, action) => {
      state[action.payload.type].data = action.payload.data;
      state[action.payload.type].totalAmount = action.payload.totalAmount;
    },
    setIsLoadingAction: (state, action) => {
      state.isLoading = action.payload;
    },
    setFilterCountAction: (state, action) => {
      state.filterCount = action.payload;
    },
  },
});

export const {
  setPositionsListAction,
  setIsLoadingAction,
  setFilterCountAction,
} = myPositionsSlice.actions;

export const selectMyPositions = (state) => state.myPositionsSlice;

export default myPositionsSlice.reducer;
