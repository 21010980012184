import {createSlice} from '@reduxjs/toolkit';

//eslint-disable-next-line
const MOCKED_CATEGORIES = [
  {
    id: 0,
    nameEn: 'Immunology',
    nameDe: 'G Immunology',
    children: [
      {id: 1, nameEn: 'AID', nameDe: 'G AID'},
      {id: 2, nameEn: 'Vasya', nameDe: 'G Vasya'},
    ],
  },
  {
    id: 3,
    nameEn: 'Therapy',
    nameDe: 'G Therapy',
    children: [
      {id: 4, nameEn: 'Manual', nameDe: 'G Manual'},
      {id: 5, nameEn: 'Manualeqweqweqwe', nameDe: 'G Manualqweqweqwe'},
      {id: 6, nameEn: 'Manual!!!', nameDe: 'G Manual!!!!'},
    ],
  },
];

const initialState = {
  categories: [],
};

const courseCategoriesSlice = createSlice({
  name: 'courseCategories',
  initialState,
  reducers: {
    setAllCourseCategories(state, action) {
      state.categories = action.payload;
    },
  },
});

export const {setAllCourseCategories} = courseCategoriesSlice.actions;

export const selectAllCourseCategories = (state) =>
  state.courseCategories.categories;

export default courseCategoriesSlice.reducer;
