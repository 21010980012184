import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  clinicData: null,
  isFetched: false,
};

const clinicDataSlice = createSlice({
  name: 'clinicData',
  initialState,
  reducers: {
    setClinicData(state, action) {
      state.clinicData = action.payload.clinicData;
      state.isFetched = action.payload.isFetched;
    },
    updateClinicVideo(state, action) {
      state.clinicData.video_url = action.payload;
    },
    updateClinicVideoPoster(state, action) {
      state.clinicData.video_preview_image = action.payload.video_preview_image;
      state.clinicData.original_video_preview_image =
        action.payload.original_video_preview_image;
    },
    updateClinicSignatureData(state, action) {
      state.clinicData.signature = action.payload.signature;
      state.clinicData.original_signature = action.payload.original_signature;
    },
    setIsFetchedStatus(state, action) {
      state.isFetched = action.payload;
    },
    unsetClinicData(state) {
      state.clinicData = null;
      state.isFetched = false;
    },
  },
});

export const {
  setClinicData,
  setIsFetchedStatus,
  unsetClinicData,
  updateClinicVideo,
  updateClinicVideoPoster,
  updateClinicSignatureData,
} = clinicDataSlice.actions;

export const selectClinicData = (state) => state.clinicData;

export default clinicDataSlice.reducer;
