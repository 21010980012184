import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
  isFetched: false,
};

const clinicSurgeriesSlice = createSlice({
  name: 'clinicSurgeries',
  initialState,
  reducers: {
    setClinicSurgeriesData(state, action) {
      state.data = action.payload.data;
      state.isFetched = action.payload.isFetched;
    },
    setIsFetchedStatus(state, action) {
      state.isFetched = action.payload;
    },
    unsetClinicSurgeries(state) {
      state.data = null;
      state.isFetched = false;
    },
  },
});

export const {
  setClinicSurgeriesData,
  setIsFetchedStatus,
  unsetClinicSurgeries,
} = clinicSurgeriesSlice.actions;

export const selectClinicSurgeriesData = (state) => state.clinicSurgeries;

export default clinicSurgeriesSlice.reducer;
