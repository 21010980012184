import {createSlice, current} from '@reduxjs/toolkit';

const initialState = {
  course: {},
  reviews: {
    data: [],
    totalAmount: null,
  },
  contentCreator: {},
};

const courseDetailsSlice = createSlice({
  name: 'courseDetails',
  initialState,
  reducers: {
    setCourse(state, action) {
      state.course = action.payload;
    },
    setReviews(state, action) {
      state.reviews = action.payload;
    },
    setContentCreatorCourses(state, action) {
      state.courses = action.payload;
    },
    setWritingReviewNotAllowed(state) {
      state.course.isWritingReviewAllowed = false;
      state.course.isHasComment = true;
    },
    setWritingReviewAllowed(state) {
      state.course.isWritingReviewAllowed = true;
    },
    setContentCreator(state, action) {
      state.contentCreator = action.payload;
    },
    setIsCourseBookmarked(state, action) {
      state.course.isBookmarked = action.payload;
    },
    updateRating(state, action) {
      state.course.rating = action.payload.rating;
      state.course.ratingCount = action.payload.ratingCount;
    },
    updateVideoTime(state, action) {
      const {videoId, stoppageTime} = action.payload;
      const currentState = current(state);

      return {
        ...currentState,
        course: {
          ...currentState.course,
          topics: currentState.course.topics.map((topic) => {
            return {
              ...topic,
              videos: topic.videos.map((video) => {
                if (video.id === videoId) {
                  return {
                    ...video,
                    stoppageTime,
                  };
                } else {
                  return video;
                }
              }),
            };
          }),
        },
      };
    },
  },
});

export const {
  setCourse,
  setContentCreator,
  setReviews,
  setIsCourseBookmarked,
  setWritingReviewNotAllowed,
  setWritingReviewAllowed,
  updateRating,
  updateVideoTime,
} = courseDetailsSlice.actions;

export const selectCourseDetails = (state) => state.courseDetails;

export default courseDetailsSlice.reducer;
