import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const getSubscriptionData = () => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/subscriptions`,
  });
};

export default getSubscriptionData;
