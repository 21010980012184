import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import getSubscriptionData from '@/src/profile/subscription/utils/getSubscriptionData';

import {resetSubscriptionData, setSubscriptionData} from './subscriptionSlice';

const useSubscriptionActions = () => {
  const dispatch = useDispatch();
  const selectSubscription = ({subscription}) => subscription;
  const subscription = useSelector(selectSubscription);

  const setSubscriptionDataAction = (subscriptionData) => {
    dispatch(setSubscriptionData(subscriptionData));
  };

  const fetchSubscription = async () => {
    const {status, responseBody} = await getSubscriptionData();

    if (status === RESPONSE_STATUSES.SUCCESS) {
      setSubscriptionDataAction(responseBody);
    }
  };

  const resetSubscriptionDataAction = () => {
    dispatch(resetSubscriptionData());
  };

  const refetchSubscription = () => {
    fetchSubscription();
  };

  return {
    subscription,
    setSubscriptionDataAction,
    resetSubscriptionDataAction,
    refetchSubscription,
  };
};

export default useSubscriptionActions;
