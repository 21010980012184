import {REST_METHODS} from '@/src/app/constants/api';
import authorizedFetch from '@/src/app/utils/authorizedFetch';

const getUserRequest = (
  serverContext: any,
  shouldSkipUnauthorizedStatus?: boolean
) => {
  // @ts-ignore
  return authorizedFetch({
    method: REST_METHODS.GET,
    path: `/api/v1/users`,
    serverContext,
    shouldSkipUnauthorizedStatus,
  });
};

export default getUserRequest;
