import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isSaving: false,
  isFetching: false,
};

export const singleJobPositionSlice = createSlice({
  name: 'singleJobPositionSlice',
  initialState,
  reducers: {
    setIsSavingAction: (state, action) => {
      state.isSaving = action.payload;
    },
    setIsFetchingAction: (state, action) => {
      state.isFetching = action.payload;
    },
    setSinglePositionAction: (state, action) => {
      state.data = action.payload;
    },
    resetSinglePositionAction: () => {
      return initialState;
    },
  },
});

export const {
  setIsSavingAction,
  setIsFetchingAction,
  setSinglePositionAction,
  resetSinglePositionAction,
} = singleJobPositionSlice.actions;

export const selectSingleJobPosition = (state) => state.singleJobPositionSlice;

export default singleJobPositionSlice.reducer;
