import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  email: '',
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setForgotPasswordEmail(state, action) {
      state.email = action.payload;
    },
  },
});

export const {setForgotPasswordEmail} = forgotPasswordSlice.actions;

export const selectForgotPasswordEmail = (state) => state.forgotPassword.email;

export default forgotPasswordSlice.reducer;
