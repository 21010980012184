import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  highlightedFacts: [],
};

const clinicHighlightedFactsSlice = createSlice({
  name: 'clinicHighlightedFacts',
  initialState,
  reducers: {
    setHighlightedFacts(state, action) {
      state.highlightedFacts = action.payload;
    },
  },
});

export const {setHighlightedFacts} = clinicHighlightedFactsSlice.actions;

export const selectClinicHighlightedFacts = (state) =>
  state.clinicHighlightedFacts;

export default clinicHighlightedFactsSlice.reducer;
