import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {getPreparedLocalPaths, ROUTES} from '@/src/app/constants/paths';

import {RouteProvider} from '../utils/RouteContext';

export let PATHS = {};
export let SEARCH_TYPE = {};

const Routes = ({children}) => {
  const {locale} = useRouter();

  const getPaths = () => {
    const paths = getPreparedLocalPaths(ROUTES, locale);

    SEARCH_TYPE = {
      CLINICS: paths.SEARCH_CLINICS.path.split('/').reverse()[0],
      COURSES: paths.SEARCH_COURSES.path.split('/').reverse()[0],
      USERS: paths.SEARCH_USERS.path.split('/').reverse()[0],
      POSTS: paths.SEARCH_POSTS.path.split('/').reverse()[0],
      FOLLOWERS: paths.SEARCH_FOLLOWERS_ROUTE.path.split('/').reverse()[0],
      FOLLOWING: paths.SEARCH_FOLLOWINGS_ROUTE.path.split('/').reverse()[0],
      WATCHLIST_FINISHED: paths.WATCHLIST_FINISHED_ROUTE.path
        .split('/')
        .reverse()[0],
      WATCHLIST_CONTINUE: paths.WATCHLIST_CONTINUE_ROUTE.path
        .split('/')
        .reverse()[0],
      WATCHLIST_BOOKMARKED: paths.WATCHLIST_BOOKMARKED_ROUTE.path
        .split('/')
        .reverse()[0],
    };
    PATHS = paths;

    return paths;
  };

  const [routes, setRoutes] = useState(getPaths());

  const addNewRoute = (newRoute) => {
    setRoutes({
      ...routes,
      ...newRoute,
    });
  };

  const preparedValues = {
    ...routes,
    helpers: {
      addNewRoute,
    },
  };

  return (
    <RouteProvider value={preparedValues} addNewPath={() => {}}>
      <>{children}</>
    </RouteProvider>
  );
};

Routes.propTypes = {
  children: PropTypes.node,
};

export default Routes;
