import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  userId: null,
  statisticsData: null,
};

const targetUserStatisticsSlice = createSlice({
  name: 'targetUserStatistics',
  initialState,
  reducers: {
    setTargetUserStatisticsDataAction(state, action) {
      return action.payload;
    },
    unsetTargetUserStatisticsDataAction() {
      return initialState;
    },
  },
});

export const {
  setTargetUserStatisticsDataAction,
  unsetTargetUserStatisticsDataAction,
} = targetUserStatisticsSlice.actions;

export default targetUserStatisticsSlice.reducer;
