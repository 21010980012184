import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  courses: [],
};

const targetUserCreatedCoursesSlice = createSlice({
  name: 'targetUserCreatedCourses',
  initialState,
  reducers: {
    setTargetUserCreatedCoursesAction(state, action) {
      state.courses = action.payload;
    },
  },
});

export const {setTargetUserCreatedCoursesAction} =
  targetUserCreatedCoursesSlice.actions;
export const targetUserCreatedCoursesSelect = (state) =>
  state.targetUserCreatedCourses;

export default targetUserCreatedCoursesSlice.reducer;
