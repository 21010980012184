import cn from 'clsx';
import PropTypes from 'prop-types';

import css from '../styles/OverlayLayout.module.css';

const OverlayLayout = ({
  isActive,
  onClick,
  withBlurOverlay,
  withHeader,
  withStrongBlueOverLay,
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(css.overlay, isActive ? css.shown : css.hidden, {
        [css.withBlurOverlay]: withBlurOverlay,
        [css.withStrongBlueOverLay]: withStrongBlueOverLay,
        [css.withHeader]: withHeader,
      })}
    />
  );
};

OverlayLayout.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OverlayLayout;
