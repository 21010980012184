import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  becomeCreatorData: null,
  doctorProfile: null,
  isFetched: false,
};

const becomeCreatorSlice = createSlice({
  name: 'becomeCreator',
  initialState,
  reducers: {
    setBecomeCreatorData(state, action) {
      state.becomeCreatorData = action.payload.becomeCreatorData;
      state.doctorProfile = action.payload.doctorProfile;
      state.isFetched = action.payload.isFetched;
    },
    setIsFetchedStatus(state, action) {
      state.isFetched = action.payload;
    },
  },
});

export const {setBecomeCreatorData, setIsFetchedStatus} =
  becomeCreatorSlice.actions;

export const selectBecomeCreatorData = (state) => state.becomeCreator;

export default becomeCreatorSlice.reducer;
