import '@/src/assets/styles/global.css';
import '@/src/assets/styles/helpers.css';
import '@/src/assets/styles/quill.css';

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {appWithTranslation} from 'next-i18next';
import PropTypes from 'prop-types';

import {wrapper} from '@/src/app/store';
import AppBootstrap from '@/src/appBootsrap/containers/AppBootstrap';
import Overlay from '@/src/overlay/containers/Overlay';
import PopupDisplayer from '@/src/popup/containers/PopupDisplayer';
import Routes from '@/src/routes/containers/Routes';

const LOCALE_MAP = {
  en: enLocale,
  de: deLocale,
};

const STAGING_ENV_VALUE = '1';

const WrappedApp = ({Component, pageProps}) => {
  const {locale} = useRouter();

  // // TODO remove if not needed, after test is done
  // useEffect(() => {
  //   TagManager.initialize({gtmId: `GTM-${process.env.NEXT_PUBLIC_GTM_KEY}`});
  // }, []);

  return (
    <>
      <Head>
        <title>MedYouCate</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        {process.env.NEXT_PUBLIC_STAGING === STAGING_ENV_VALUE && (
          <meta name="robots" content="noindex" />
        )}
      </Head>

      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={LOCALE_MAP[locale]}
      >
        <Routes>
          <AppBootstrap>
            <>
              <PopupDisplayer>
                <>
                  <Overlay />
                  <Component {...pageProps} />
                </>
              </PopupDisplayer>
            </>
          </AppBootstrap>
        </Routes>
      </LocalizationProvider>
    </>
  );
};

WrappedApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.object,
};

export default wrapper.withRedux(appWithTranslation(WrappedApp));
