import {combineReducers} from '@reduxjs/toolkit';

import employmentTypesDictionary from '@/src/app/store/user/employmentTypesDictionarySlice';

import clinicAwards from './clinic/clinicAwardsSlice';
import clinicCompetencesDictionary from './clinic/clinicCompetencesDictionarySlice';
import clinicCompetences from './clinic/clinicCompetencesSlice';
import clinicData from './clinic/clinicDataSlice';
import clinicEmployees from './clinic/clinicEmployessSlice';
import clinicFacts from './clinic/clinicFactsSlice';
import clinicHighlightedFacts from './clinic/clinicHighlightedFactsSlice';
import clinicHighlightedSurgeries from './clinic/clinicHighlightedSurgeriesSlice';
import clinicPublications from './clinic/clinicPublicationsSlice';
import clinicSurgeries from './clinic/clinicSurgeriesSlice';
import contentCreatorWidget from './contentCreatorWidget/contentCreatorWidgetSlice';
import courseDetails from './courseDetails/courseDetailsSlice';
import courseCategories from './createCourse/courseCategoriesSlice';
import createCourse from './createCourse/createCourseSlice';
import creatorStudio from './creatorStudio/creatorStudioSlice';
import forgotPassword from './forgotPassword/forgotPasswordSlice';
import myPositionsSlice from './jobPortal/myPositionsSlice';
import singleJobPositionSlice from './jobPortal/singleJobPositionSlice';
import overlay from './overlay/overlaySlice';
import plans from './plans/plansSlice';
import promoSections from './promoSections/promoSectionsSlice';
import reviewsWidget from './reviewsWidget/reviewsWidgetSlice';
import search from './search/searchSlice';
import countriesDictionary from './signUp/countriesDictionarySlice';
import subscription from './subscription/subscriptionSlice';
import clinicContactPersons from './targetClinic/clinicContantPersonsSlice';
import targetClinicSlice from './targetClinic/clinicTargetSlice';
import reportReasons from './targetUser/reportReasonsSlice';
import targetUserCertificates from './targetUser/targetUserCertificatesReducer';
import targetUserCreatedCourses from './targetUser/targetUserCreatedCourses';
import targetUserPreviouslyWatchedCourses from './targetUser/targetUserPreviouslyWatchedCourses';
import targetUser from './targetUser/targetUserReducer';
import targetUserStatistics from './targetUser/targetUserStatisticsReducer';
import becomeCreator from './user/becomeCreatorSlice';
import deleteAccount from './user/deleteAccountSlice';
import jobOpportunitiesSlice from './user/jobOpportunitiesSlice';
import myCertificates from './user/myCertificatesSlice';
import skillsDictionary from './user/skillsDictionarySlice';
import surgeriesTypes from './user/surgeriesTypesSlice';
import user from './user/userReducer';
import withdrawData from './user/withdrawDataSlice';
import watchedCourses from './watchedCourses/watchedCoursesSlice';

export const reducers = combineReducers({
  user,
  deleteAccount,
  forgotPassword,
  plans,
  becomeCreator,
  createCourse,
  courseCategories,
  overlay,
  surgeriesTypes,
  creatorStudio,
  promoSections,
  skillsDictionary,
  courseDetails,
  search,
  subscription,
  targetUser,
  clinicData,
  clinicSurgeries,
  clinicHighlightedSurgeries,
  clinicAwards,
  clinicCompetencesDictionary,
  clinicCompetences,
  clinicPublications,
  reportReasons,
  withdrawData,
  targetUserStatistics,
  employmentTypesDictionary,
  contentCreatorWidget,
  reviewsWidget,
  watchedCourses,
  countriesDictionary,
  myCertificates,
  clinicContactPersons,
  targetUserCertificates,
  targetUserCreatedCourses,
  targetUserPreviouslyWatchedCourses,
  targetClinicSlice,
  clinicEmployees,
  jobOpportunitiesSlice,
  singleJobPositionSlice,
  myPositionsSlice,
  clinicFacts,
  clinicHighlightedFacts,
});
