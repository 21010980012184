export const SEARCH_FILTERS = {
  USER_ID: 'user_id',
  SEARCH: 'search',
  OFFSET: 'offset',
  PER_PAGE: 'per_page',
  ORDER: 'order',
  ORDER_BY: 'order_by',
  LANGUAGE: 'language',
  FILTERS_STATUS: 'filters[status]',
  FILTERS_ORIGINAL: 'filters[medyoucate_original]',
  FILTERS_CATEGORY_IDS: 'filters[category_ids]',
  FILTERS_DIFFICULTY_LEVELS: 'filters[difficulty_levels]',
  FILTERS_AVERAGE_SCORE: 'filters[average_score]',
  FILTERS_TYPE: 'filters[type]',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  LANGUAGES: 'filters[languages]',
  CLINIC_ID: 'clinic_id',
  CLINIC_IDS: 'filters[clinic_ids]',
  CLINIC_NAME: 'clinic_name',
  ALL_COUNTRIES_IDS: 'filters[country_ids]',
  COUNTRY_IDS: 'filters[open_for_new_job_country_ids]',
  CLINIC_COUNTRIES: 'filters[country_id]',
  CLINIC_STATE: 'filters[state_id]',
  CLINIC_CITY: 'filters[city_id]',
  PREFERABLE_POSITION: 'filters[preferable_position_id]',
  SALARY_MIN: 'filters[min_salary]',
  SALARY_MAX: 'filters[max_salary]',
  WORK_TYPES: 'filters[work_type]',
  SPECIALTIES: 'filters[specialties_ids]',
  POSITIONS: 'filters[positions_ids]',
};

export const SEARCH_HELPER_FIRST_NAME = 'FIRST_NAME';
export const SEARCH_HELPER_LAST_NAME = 'LAST_NAME';

export const SEARCH_HELPER_KEYS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
};

export const ORDER_BY_FILTER = {
  COURSE_CREATED_AT: 'course_created_at',
  AVERAGE_SCORE: 'average_score',
  APPROVED_AT: 'approved_at',
  VACANCY_PUBLISH: 'vacancy_published_at',
  VACANCY_EXPIRED: 'vacancy_expire_date',
  SALARY: 'vacancy_min_salary',
  ENTRY_DATE: 'vacancy_entry_date',
};

export const ORDER_FILTER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const FILTERS_STATUS_FILTER = {
  REVIEW: 'review',
  APPROVED: 'approved',
  DRAFT: 'draft',
  DECLINED: 'declined',
  DELETED: 'deleted',
};

export const SEARCH_TYPE = {
  COURSES: 'courses',
  USERS: 'users',
  FOLLOWERS: 'followers',
  FOLLOWING: 'following',
  WATCHLIST_BOOKMARKED: 'bookmarked',
  WATCHLIST_CONTINUE: 'continue',
  WATCHLIST_FINISHED: 'finished',
  UNITED: 'united',
  EMPLOYEES: 'employees',
  ALL_CANDIDATES: 'all-candidates',
  CLINIC_VACANCIES: 'clinic-vacancies',
  CLINICS: 'clinics',
  ALL_POSITIONS: 'all-positions',
  VACANCIES: 'vacancies',
  POSTS: 'posts',
};

export const ITEMS_PER_PAGE = {
  [SEARCH_TYPE.COURSES]: 18,
  [SEARCH_TYPE.USERS]: 12,
  [SEARCH_TYPE.FOLLOWING]: 12,
  [SEARCH_TYPE.FOLLOWERS]: 12,
  [SEARCH_TYPE.WATCHLIST_BOOKMARKED]: 18,
  [SEARCH_TYPE.WATCHLIST_CONTINUE]: 18,
  [SEARCH_TYPE.WATCHLIST_FINISHED]: 18,
  [SEARCH_TYPE.EMPLOYEES]: 12,
  [SEARCH_TYPE.ALL_CANDIDATES]: 12,
  [SEARCH_TYPE.CLINIC_VACANCIES]: 6,
  [SEARCH_TYPE.VACANCIES]: 6,
  [SEARCH_TYPE.CLINICS]: 12,
  [SEARCH_TYPE.POSTS]: 12,
  [SEARCH_TYPE.ALL_POSITIONS]: 12,
};

export const RESULT_DATA_TYPE = {
  USER: 'user',
  COURSE: 'course',
  VACANCY: 'vacancy',
  CLINICS: 'clinics',
  FOLLOW: 'follow',
  POSTS: 'posts',
};
