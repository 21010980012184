import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  userId: null,
  certificatesData: null,
};

const targetUserCertificatesSlice = createSlice({
  name: 'targetUserCertificates',
  initialState,
  reducers: {
    setTargetUserCertificatesDataAction(state, action) {
      return action.payload;
    },
  },
});

export const {setTargetUserCertificatesDataAction} =
  targetUserCertificatesSlice.actions;

export const selectTargetUserCertificatesData = (state) =>
  state.targetUserCertificates;

export default targetUserCertificatesSlice.reducer;
