import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  facts: [],
};

const clinicFactsSlice = createSlice({
  name: 'clinicFacts',
  initialState,
  reducers: {
    setFacts(state, action) {
      state.facts = action.payload;
    },
  },
});

export const {setFacts} = clinicFactsSlice.actions;

export const selectClinicFacts = (state) => state.clinicFacts;

export default clinicFactsSlice.reducer;
