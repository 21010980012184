import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isFetched: false,
};

const clinicCompetencesSlice = createSlice({
  name: 'clinicCompetences',
  initialState,
  reducers: {
    setClinicCompetencesData(state, action) {
      state.data = action.payload.data;
      state.isFetched = action.payload.isFetched;
    },
    setIsFetchedStatus(state, action) {
      state.isFetched = action.payload;
    },
    unsetClinicCompetences(state) {
      state.data = null;
      state.isFetched = false;
    },
  },
});

export const {
  setClinicCompetencesData,
  setIsFetchedStatus,
  unsetClinicCompetences,
} = clinicCompetencesSlice.actions;

export const selectClinicCompetencesData = (state) => state.clinicCompetences;

export default clinicCompetencesSlice.reducer;
