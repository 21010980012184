import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
  clinicId: null,
  isFetched: false,
};

const clinicContactPersonsSlice = createSlice({
  name: 'clinicContactPersons',
  initialState,
  reducers: {
    setClinicContactPersonsData(state, action) {
      state.data = action.payload.data;
      state.isFetched = action.payload.isFetched;
      state.clinicId = action.payload.clinicId;
    },
    setIsFetchedStatus(state, action) {
      state.isFetched = action.payload;
    },
    unsetClinicContactPersons(state) {
      state.data = null;
      state.clinicId = null;
      state.isFetched = false;
    },
  },
});

export const {
  setClinicContactPersonsData,
  setIsFetchedStatus,
  unsetClinicContactPersons,
} = clinicContactPersonsSlice.actions;

export const selectClinicContactPersonsData = (state) =>
  state.clinicContactPersons;

export default clinicContactPersonsSlice.reducer;
