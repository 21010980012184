import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isFetched: false,
  data: null,
};

const clinicCompetencesDictionarySlice = createSlice({
  name: 'clinicCompetencesDictionary',
  initialState,
  reducers: {
    setClinicCompetencesDictionary(state, action) {
      state.data = action.payload;
    },
    setIsFetched(state, action) {
      state.isFetched = action.payload;
    },
  },
});

export const {setClinicCompetencesDictionary, setIsFetched} =
  clinicCompetencesDictionarySlice.actions;

export const selectClinicCompetencesDictionary = (state) =>
  state.clinicCompetencesDictionary;

export default clinicCompetencesDictionarySlice.reducer;
