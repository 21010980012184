import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isFetched: false,
};

const clinicAwardsSlice = createSlice({
  name: 'clinicAwards',
  initialState,
  reducers: {
    setClinicAwardsData(state, action) {
      state.data = action.payload.data;
      state.isFetched = action.payload.isFetched;
    },
    setIsFetchedStatus(state, action) {
      state.isFetched = action.payload;
    },
    unsetClinicAwards(state) {
      state.data = null;
      state.isFetched = false;
    },
  },
});

export const {setClinicAwardsData, setIsFetchedStatus, unsetClinicAwards} =
  clinicAwardsSlice.actions;

export const selectClinicAwardsData = (state) => state.clinicAwards;

export default clinicAwardsSlice.reducer;
