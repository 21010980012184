import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  courses: {
    data: [],
    meta: 0,
  },
  course: {
    course_translations: [],
  },
};

const createCourseSlice = createSlice({
  name: 'creatorStudio',
  initialState,
  reducers: {
    setCourses(state, action) {
      state.courses = action.payload;
    },
    setCourse(state, action) {
      state.course = action.payload;
    },
    updateTranslations(state, action) {
      state.course.course_translations = action.payload;
    },
  },
});

export const {setCourses, setCourse, updateTranslations} =
  createCourseSlice.actions;

export const selectCourseStudio = (state) => state.creatorStudio;

export default createCourseSlice.reducer;
