import {destroyCookie, parseCookies, setCookie} from 'nookies';
export const STORAGE_KEY = 'authorizationToken';

export const getAuthorizationToken = (serverContext) => {
  const isServerEnvironment = typeof window === 'undefined' && serverContext;

  if (!isServerEnvironment) {
    const {authorizationToken} = parseCookies();

    return authorizationToken;
  } else {
    const {authorizationToken} = parseCookies(serverContext);

    return authorizationToken;
  }
};

export const setAuthorizationToken = (token) => {
  removeAuthorizationToken();
  setCookie(null, STORAGE_KEY, token, {
    path: '/',
    sameSite: 'strict',
    secure: true,
  });
};

export const removeAuthorizationToken = () => {
  destroyCookie(null, STORAGE_KEY, {
    path: '/',
  });
};
