import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  clinic: {
    data: null,
    isLoading: false,
  },
  clinicUser: {
    data: null,
    isLoading: false,
  },
  statistic: {
    data: {
      followers: 0,
      watched_courses: 0,
      created_courses: 0,
      watched_duration: 0,
      jop_positions: 0,
    },
    isLoading: false,
  },
  images: {
    data: [],
    isLoading: false,
  },
  clinicAwards: {
    data: [],
    isFetched: false,
  },
  clinicSurgeries: {
    data: [],
    isFetched: false,
  },
  clinicHighlightedSurgeries: {
    data: [],
    isFetched: false,
  },
  clinicSpecialties: {
    data: [],
    isFetched: false,
  },
  clinicLinks: {
    data: [],
    isFetched: false,
  },
  clinicPdfs: {
    data: [],
    isFetched: false,
  },
  clinicEmployees: {
    data: [],
    totalAmount: 0,
    isFetched: false,
  },
  clinicVacancies: {
    data: [],
    isFetched: false,
    totalNumber: 0,
  },
};

const targetClinicSlice = createSlice({
  name: 'targetClinicSlice',
  initialState,
  reducers: {
    setTargetClinicData(state, action) {
      state.clinic.data = action.payload;
      state.clinic.isLoading = false;
    },
    setFollowClinic(state, action) {
      state.clinic.data.is_followed = action.payload;
    },
    setTargetClinicLoading(state, action) {
      state.clinic.isLoading = action.payload;
    },
    setTargetClinicStatistic(state, action) {
      state.statistic.data = action.payload;
    },
    setTargetClinicStatisticLoading(state, action) {
      state.statistic.isLoading = action.payload;
    },
    setTargetUserClinic(state, action) {
      state.clinicUser.data = action.payload;
    },
    setTargetUserClinicLoading(state, action) {
      state.clinicUser.isLoading = action.payload;
    },
    setTargetClinicImagesLoading(state, action) {
      state.images.isLoading = action.payload;
    },
    setTargetClinicImages(state, action) {
      state.images.data = action.payload;
    },
    setTargetClinicAwards(state, action) {
      state.clinicAwards.data = action.payload;
      state.clinicAwards.isFetched = true;
    },
    setTargetClinicSurgeries(state, action) {
      state.clinicSurgeries.data = action.payload;
      state.clinicSurgeries.isFetched = true;
    },
    setTargetClinicHighlightedSurgeries(state, action) {
      state.clinicHighlightedSurgeries.data = action.payload;
      state.clinicHighlightedSurgeries.isFetched = true;
    },
    setTargetClinicSpecialties(state, action) {
      state.clinicSpecialties.data = action.payload;
      state.clinicSpecialties.isFetched = true;
    },
    setTargetClinicLinks(state, action) {
      state.clinicLinks.data = action.payload;
      state.clinicLinks.isFetched = true;
    },
    setTargetClinicEmployees(state, action) {
      state.clinicEmployees.data = action.payload.data;
      state.clinicEmployees.totalAmount = action.payload.totalAmount;
      state.clinicEmployees.isFetched = true;
    },
    setTargetClinicPdfs(state, action) {
      state.clinicPdfs.data = action.payload;
      state.clinicPdfs.isFetched = true;
    },
    setTargetClinicVacancies(state, action) {
      state.clinicVacancies.data = action.payload.data;
      state.clinicVacancies.totalNumber = action.payload.totalNumber;
      state.clinicVacancies.isFetched = true;
    },
    resetClinicData() {
      return initialState;
    },
  },
});

export const {
  setTargetClinicData,
  setTargetClinicLoading,
  resetClinicData,
  setTargetClinicStatistic,
  setTargetClinicStatisticLoading,
  setTargetClinicImages,
  setTargetClinicImagesLoading,
  setTargetUserClinic,
  setTargetUserClinicLoading,
  setTargetClinicAwards,
  setTargetClinicSurgeries,
  setTargetClinicHighlightedSurgeries,
  setTargetClinicSpecialties,
  setTargetClinicLinks,
  setTargetClinicEmployees,
  setTargetClinicPdfs,
  setTargetClinicVacancies,
  setFollowClinic,
} = targetClinicSlice.actions;

export const selectTargetClinic = (state) => state.targetClinicSlice;

export default targetClinicSlice.reducer;
