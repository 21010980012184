import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  types: null,
};

const surgeriesTypesSlice = createSlice({
  name: 'surgeriesTypes',
  initialState,
  reducers: {
    setSurgeriesTypes(state, action) {
      state.types = action.payload;
    },
  },
});

export const {setSurgeriesTypes} = surgeriesTypesSlice.actions;

export const selectSurgeriesTypesSlice = (state) => state.surgeriesTypes.types;

export default surgeriesTypesSlice.reducer;
