import cn from 'clsx';
import Image from 'next/image';
import PropTypes from 'prop-types';

import css from '../styles/PopupLayout.module.css';

export const POPUP_TEST_IDS = {
  CLOSE_BUTTON: 'POPUP_CLOSE_BUTTON',
};

const PopupLayout = ({
  popupNode,
  withCloseButton,
  withTransparentBackground,
  onCloseClick,
  popupBodyRef,
  disableShadow,
}) => {
  return (
    <div
      className={cn(css.popupContainer, !popupNode && css.popupContainerHidden)}
    >
      <div
        className={cn(css.popupBody, {
          [css.transparentBackground]: withTransparentBackground,
          [css.disableShadow]: disableShadow,
        })}
        ref={popupBodyRef}
      >
        {Boolean(withCloseButton) && (
          <div className={css.closeIcon}>
            <Image
              data-testid={POPUP_TEST_IDS.CLOSE_BUTTON}
              src={`/icons/closePopup.svg`}
              loading="lazy"
              alt="true"
              width="18"
              height="18"
              onClick={onCloseClick}
            />
          </div>
        )}
        {Boolean(popupNode) && popupNode}
      </div>
    </div>
  );
};

PopupLayout.propTypes = {
  popupNode: PropTypes.node,
  withCloseButton: PropTypes.bool,
  withBlurBackground: PropTypes.bool,
  withTransparentBackground: PropTypes.bool,
  onCloseClick: PropTypes.func,
  popupBodyRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default PopupLayout;
