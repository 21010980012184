import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
  isFetched: false,
};

const clinicHighlightedSurgeriesSlice = createSlice({
  name: 'clinicHighlightedSurgeries',
  initialState,
  reducers: {
    setClinicHighlightedSurgeriesData(state, action) {
      state.data = action.payload.data;
      state.isFetched = action.payload.isFetched;
    },
    setIsFetchedStatus(state, action) {
      state.isFetched = action.payload;
    },
    unsetClinicHighlightedSurgeries(state) {
      state.data = null;
      state.isFetched = false;
    },
  },
});

export const {
  setClinicHighlightedSurgeriesData,
  setIsFetchedStatus,
  unsetClinicHighlightedSurgeries,
} = clinicHighlightedSurgeriesSlice.actions;

export const selectClinicHighlightedSurgeriesData = (state) =>
  state.clinicHighlightedSurgeries;

export default clinicHighlightedSurgeriesSlice.reducer;
