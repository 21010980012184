import {HYDRATE} from 'next-redux-wrapper';
import {mergeDeepRight} from 'ramda';

import {reducers} from './reducers';

const rootReducer = (state, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...mergeDeepRight(state, action.payload),
      };

    default: {
      return reducers(state, action);
    }
  }
};

export default rootReducer;
