export const ROLES_NAMES = {
  STUDENT: 'student',
  CUSTOMER: 'customer',
  DOCTOR: 'doctor',
  COURSE_CREATOR: 'content_creator',
  CLINIC: 'clinic',
};

export const ROLE_BENEFITS = {
  COURSES: 'role-benefits.access-to-courses',
  JOB_PORTAL: 'role-benefits.job-portal',
  CERTIFICATES: 'role-benefits.receive-certificates',
  VIDEOS: 'role-benefits.publish-your-videos',
};

export const SING_UP_USERS = [
  {
    label: 'userType.doctor',
    value: ROLES_NAMES.DOCTOR,
    info: 'userType.doctor-tooltip',
    icon: 'doctor.svg',
    benefits: [
      ROLE_BENEFITS.COURSES,
      ROLE_BENEFITS.CERTIFICATES,
      ROLE_BENEFITS.JOB_PORTAL,
    ],
  },
  {
    label: 'userType.student-signup',
    value: ROLES_NAMES.STUDENT,
    info: 'userType.student-tooltip',
    icon: 'student.svg',
    benefits: [
      ROLE_BENEFITS.COURSES,
      ROLE_BENEFITS.CERTIFICATES,
      ROLE_BENEFITS.JOB_PORTAL,
    ],
  },
  {
    label: 'userType.content-creator',
    value: ROLES_NAMES.COURSE_CREATOR,
    info: 'userType.content-creator-tooltip',
    icon: 'contentCreator.svg',
    benefits: [
      ROLE_BENEFITS.COURSES,
      ROLE_BENEFITS.CERTIFICATES,
      ROLE_BENEFITS.JOB_PORTAL,
      ROLE_BENEFITS.VIDEOS,
    ],
  },
  {
    label: 'userType.clinic',
    value: ROLES_NAMES.CLINIC,
    info: 'userType.clinic-tooltip',
    icon: 'clinic.svg',
    benefits: [
      ROLE_BENEFITS.COURSES,
      ROLE_BENEFITS.CERTIFICATES,
      ROLE_BENEFITS.JOB_PORTAL,
      ROLE_BENEFITS.VIDEOS,
    ],
  },
];

export const ROLES_LIST = [
  {
    label: 'userType.doctor',
    value: ROLES_NAMES.DOCTOR,
    info: 'userType.doctor-tooltip',
  },
  {
    label: 'userType.student',
    value: ROLES_NAMES.STUDENT,
    info: 'userType.student-tooltip',
  },
  {
    label: 'userType.content-creator',
    value: ROLES_NAMES.COURSE_CREATOR,
    info: 'userType.content-creator-tooltip',
  },
  {
    label: 'userType.regular-user',
    value: ROLES_NAMES.CUSTOMER,
    info: 'userType.regular-user-tooltip',
  },
  {
    label: 'userType.clinic',
    value: ROLES_NAMES.CLINIC,
    info: 'userType.clinic-tooltip',
  },
];
