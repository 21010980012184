import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import {toggleOverlay} from '@/src/app/store/overlay/overlaySlice';

import PopupLayout from '../components/PopupLayout';
import {PopupProvider} from '../utils/PopupContext';

const BODY_POPUP_CLASS = 'bodyPopupOpen';

const PopupDisplayer = ({children}) => {
  const popupBodyRef = useRef(null);
  const closeCallbackRef = useRef(() => {});
  const dispatch = useDispatch();
  const [popupNode, setPopupNode] = useState(null);
  const [popupOptions, setPopupOptions]: Array<any> = useState({});
  const [withBlurOverlay, setWithBlurOverlay] = useState(false);
  const [withHeader, setWithHeader] = useState(false);
  const {asPath} = useRouter();

  useEffect(() => {
    if (popupNode) {
      document.body.classList.add(BODY_POPUP_CLASS);
      popupBodyRef.current.scrollTop = 0;
    } else {
      document.body.classList.remove(BODY_POPUP_CLASS);
    }
  }, [popupNode]);

  useEffect(() => {
    closePopup();
  }, [asPath]);

  const openPopup = useCallback(
    ({popup, options = {}}) => {
      dispatch(toggleOverlay(true));
      setPopupNode(popup);
      setPopupOptions(options);

      // @ts-ignore
      setWithBlurOverlay(options.withBlurOverlay);
      // @ts-ignore
      setWithHeader(options.withHeader);
      // @ts-ignore
      options?.onClose && (closeCallbackRef.current = options?.onClose);
    },
    [setPopupNode, setPopupOptions]
  );

  const closePopup = useCallback(() => {
    dispatch(toggleOverlay(false));
    setPopupNode(null);
    setPopupOptions({});
  }, [setPopupNode, setPopupOptions]);

  const handleCloseClick = useCallback(() => {
    if (popupOptions.onClose) {
      popupOptions.onClose();
    }

    closeCallbackRef.current();
    closePopup();
  }, [closePopup, popupOptions]);

  const isPopupOpened = useMemo(() => {
    return Boolean(popupNode);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closePopup();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <PopupProvider
      value={{
        // @ts-ignore
        openPopup,
        closePopup: handleCloseClick,
        isPopupOpened,
        withBlurOverlay,
        withHeader,
      }}
    >
      <PopupLayout
        popupNode={popupNode}
        disableShadow={popupOptions.disableShadow}
        withCloseButton={popupOptions.withCloseButton}
        withTransparentBackground={popupOptions.withTransparentBackground}
        onCloseClick={handleCloseClick}
        // @ts-ignore
        popupOptions={popupOptions}
        popupBodyRef={popupBodyRef}
      />
      {children}
    </PopupProvider>
  );
};

PopupDisplayer.propTypes = {
  children: PropTypes.node,
};

export default PopupDisplayer;
