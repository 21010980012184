import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isFetched: false,
  data: null,
};

const employmentTypesDictionarySlice = createSlice({
  name: 'employmentTypesDictionary',
  initialState,
  reducers: {
    setEmploymentTypesDictionary(state, action) {
      state.data = action.payload;
    },
    setEmploymentTypesDictionaryFetchingStatus(state, action) {
      state.isFetched = action.payload;
    },
  },
});

export const {
  setEmploymentTypesDictionary,
  setEmploymentTypesDictionaryFetchingStatus,
} = employmentTypesDictionarySlice.actions;

export const selectEmploymentTypesDictionarySlice = (state) =>
  state.employmentTypesDictionary;

export default employmentTypesDictionarySlice.reducer;
