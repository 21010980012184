import {createSlice} from '@reduxjs/toolkit';

import {LOCALES} from '../../constants/locales';
import {ROLES_NAMES} from '../../constants/roles';

const initialState = {
  user: {
    id: null,
    email: '',
    type: ROLES_NAMES.CUSTOMER,
    first_name: '',
    last_name: '',
    username: '',
    skills: [],
  },
  profile: {
    biography: '',
    job_title: '',
    university_name: '',
    academic_title: '',
    language: LOCALES.DE,
  },
  highlighted_surgeries: [],
  surgeries: [],
};

const targetUserSlice = createSlice({
  name: 'targetUser',
  initialState,
  reducers: {
    setTargetUserDataAction(state, action) {
      return action.payload;
    },
    unsetTargetUserDataAction() {
      return initialState;
    },
  },
});

export const {setTargetUserDataAction, unsetTargetUserDataAction} =
  targetUserSlice.actions;
export default targetUserSlice.reducer;
