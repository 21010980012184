import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  data: {
    participation: '',
    positions: [],
    location: {},
  },
};

const jobOpportunitiesSlice = createSlice({
  name: 'jobOpportunitiesSlice',
  initialState,
  reducers: {
    setJobOpportunities(state, action) {
      state.data = action.payload;
    },
    setJobOpportunitiesLoading(state, action: {payload: boolean}) {
      state.isLoading = action.payload;
    },

    resetJobOpportunities() {
      return initialState;
    },
  },
});

export const {
  setJobOpportunities,
  setJobOpportunitiesLoading,
  resetJobOpportunities,
} = jobOpportunitiesSlice.actions;

export const selectJobOpportunitiesSlice = (state) =>
  state.jobOpportunitiesSlice;

export default jobOpportunitiesSlice.reducer;
