import {FILTERS_STATUS_FILTER} from '@/src/app/constants/search';

import permissionsList from '../constants/permissionsList';
import {ROLES_NAMES} from '../constants/roles';

const prepareUserPermissionAccess = (userPermissions, userType, user) => {
  return {
    accessDeleteCourse: userPermissions.includes(
      permissionsList.CAN_DELETE_COURSE
    ),
    accessCreateCourse: userPermissions.includes(
      permissionsList.CAN_CREATE_COURSE
    ),
    accessUpdateCourse: userPermissions.includes(
      permissionsList.CAN_UPDATE_COURSE
    ),
    accessCreatorStudioCourseList:
      (userType === ROLES_NAMES.COURSE_CREATOR ||
        userType === ROLES_NAMES.CLINIC) &&
      userPermissions.includes(permissionsList.CAN_CREATE_COURSE),
    accessWatchCourse: userPermissions.includes(
      permissionsList.CAN_READ_COURSE
    ),
    accessCreatePosition:
      userType === ROLES_NAMES.CLINIC &&
      user?.status === FILTERS_STATUS_FILTER.APPROVED,
  };
};

export default prepareUserPermissionAccess;
