import {createSlice} from '@reduxjs/toolkit';
import {v4 as uuidv4} from 'uuid';

import {
  COURSE_VIDEO_TEMPLATE,
  DIFFICULTY_LEVELS,
  TOPIC_TEMPLATE,
} from '../../constants/course';

const getTopicTemplate = () => {
  return {
    ...TOPIC_TEMPLATE,
    videos: [
      {
        ...COURSE_VIDEO_TEMPLATE,
        id: uuidv4(),
      },
    ],
  };
};

const initialState = {
  selectedLanguages: [],
  difficultyLevel: DIFFICULTY_LEVELS.ALL,
  categories: [],
  id: null,
  currentLang: null,
  en: {
    id: null,
    title: '',
    description: '',
    requirements: '',
    targetGroup: '',
    video: '',
    previewImage: '',
    topics: getTopicTemplate(),
  },
  de: {
    id: null,
    title: null,
    description: '',
    requirements: '',
    targetGroup: '',
    video: '',
    previewImage: '',
    topics: getTopicTemplate(),
  },
};

const createCourseSlice = createSlice({
  name: 'createCourse',
  initialState,
  reducers: {
    setFirstStepData(state, action) {
      state.selectedLanguages = action.payload.selectedLanguages;
      state.difficultyLevel = action.payload.difficultyLevel;
      state.categories = action.payload.categories;
      state.id = action.payload.id;
      state.currentLang = action.payload.selectedLanguages[0];
    },
    changeEditLang(state, action) {
      state.currentLang = action.payload;
    },
    saveCourse(state, action) {
      state[action.payload.lang + 'Saved'] = state[action.payload.lang];
    },
    saveLangStep(state, action) {
      state[action.payload.lang] = {
        ...state[action.payload.lang],
        lastStep: action.payload.step,
      };
    },
    saveCourseOnChange(state, action) {
      state[action.payload.lang] = {
        ...state[action.payload.lang],
        ...action.payload.values,
      };
    },
    addNewTopic(state, action) {
      state[action.payload.lang].topics.push(getTopicTemplate());
    },
    saveTopics(state, action) {
      state[action.payload.lang].topics[action.payload.index] =
        action.payload.topic;
    },
    deleteTopic(state, action) {
      state[action.payload.lang].topics = action.payload.topics;
    },
    changeEditMode(state, action) {
      state[action.payload.lang].topics[action.payload.index].isEditMode =
        action.payload.isEditMode;
    },
    setEditCourse(state, action) {
      return (state = action.payload);
    },
    setEditModeTopicsOff(state, action) {
      state[action.payload.lang].topics = action.payload.topics;
    },
    resetCourseData() {
      return initialState;
    },
    updateTopicsOrder(state, action) {
      state[action.payload.language].topics = action.payload.topics;
    },
  },
});

export const {
  setFirstStepData,
  changeEditLang,
  saveCourse,
  saveCourseOnChange,
  addNewTopic,
  saveLangStep,
  saveTopics,
  changeEditMode,
  setEditCourse,
  deleteTopic,
  setEditModeTopicsOff,
  resetCourseData,
  updateTopicsOrder,
} = createCourseSlice.actions;

export const selectNewCourseData = (state) => state.createCourse;

export default createCourseSlice.reducer;
