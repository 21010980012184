import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isFetched: false,
  countriesDictionary: null,
};

const countriesDictionarySlice = createSlice({
  name: 'countriesDictionary',
  initialState,
  reducers: {
    setCountriesDictionary(state, action) {
      state.countriesDictionary = action.payload;
    },
    setCountriesDictionaryFetchingStatus(state, action) {
      state.isFetched = action.payload;
    },
  },
});

export const {setCountriesDictionary, setCountriesDictionaryFetchingStatus} =
  countriesDictionarySlice.actions;

export const selectCountriesDictionarySlice = (state) =>
  state.countriesDictionary;

export default countriesDictionarySlice.reducer;
