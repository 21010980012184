import {pathOr} from 'ramda';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import getUserRequest from '@/src/appBootsrap/utils/getUserRequest';

import prepareUserPermissionAccess from '../../utils/prepareUserPermissionAccess';
import {
  changeLocalAction,
  deleteSignatureAction,
  setLoadingUser,
  setUserDataAction,
  setUserSignature,
  unsetUserDataAction,
} from './userReducer';

const useUserActions = () => {
  const selectUser = ({user}) => user;
  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const setUserData = (user) => {
    const userPermissionsList = pathOr([], ['user', 'permissions'], user);

    const permissions = prepareUserPermissionAccess(
      userPermissionsList,
      user.user.type,
      user.user
    );
    dispatch(
      setUserDataAction({
        ...user,
        permissions,
      })
    );
  };

  const unsetUserData = () => {
    dispatch(unsetUserDataAction());
  };

  const updateSignature = (signature) => {
    dispatch(setUserSignature(signature));
  };

  const changeLocale = (newLocale) => {
    dispatch(changeLocalAction(newLocale));
  };

  const deleteSignature = () => {
    dispatch(deleteSignatureAction());
  };

  const requestUserData = useCallback(async () => {
    if (getAuthorizationToken()) {
      dispatch(setLoadingUser(true));

      const {status, responseBody} = await getUserRequest();

      if (status === RESPONSE_STATUSES.SUCCESS) {
        setUserData(responseBody);
      }

      dispatch(setLoadingUser(false));
    }
  }, []);

  return {
    setUserData,
    requestUserData,
    unsetUserData,
    changeLocale,
    updateSignature,
    deleteSignature,
    user,
  };
};

export default useUserActions;
