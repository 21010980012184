import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  results: {},
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setResultsData(state, action) {
      state.results = action.payload;
    },
  },
});

export const {setResultsData} = searchSlice.actions;

export const selectResults = (state) => state.search.results;

export default searchSlice.reducer;
