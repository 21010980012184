import {createSlice} from '@reduxjs/toolkit';

export const overlaySlice = createSlice({
  name: 'overlay',
  initialState: {
    isActive: false,
  },
  reducers: {
    toggleOverlay: (state, action) => {
      state.isActive = action.payload;
    },
  },
});

export const {toggleOverlay} = overlaySlice.actions;

export const selectOverlay = (state) => state.overlay.isActive;

export default overlaySlice.reducer;
