import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {createWrapper} from 'next-redux-wrapper';

import slice from './slices';

export const devMode = process.env.NODE_ENV === 'development';

export const store = configureStore({
  reducer: slice,
  middleware: [...getDefaultMiddleware()],
  devTools: devMode,
});

const setupStore = () => store;

const makeStore = (context) => setupStore(context);

export const wrapper = createWrapper(makeStore, {
  debug: false,
});

export default wrapper;
