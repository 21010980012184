import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  userId: null,
  coursesData: null,
};

const targetUserPreviouslyWatchedCourses = createSlice({
  name: 'targetUserPreviouslyWatchedCourses',
  initialState,
  reducers: {
    setTargetUserPreviouslyWatchedCoursesAction(state, action) {
      return action.payload;
    },
  },
});

export const {setTargetUserPreviouslyWatchedCoursesAction} =
  targetUserPreviouslyWatchedCourses.actions;

export const selectTargetUserPreviouslyWatchedCoursesData = (state) =>
  state.targetUserPreviouslyWatchedCourses;

export default targetUserPreviouslyWatchedCourses.reducer;
