import {useContext} from 'react';
import {useSelector} from 'react-redux';

import {selectOverlay} from '@/src/app/store/overlay/overlaySlice';
import PopupContext from '@/src/popup/utils/PopupContext';

import OverlayLayout from '../components/OverlayLayout';

const Overlay = () => {
  const {closePopup, withBlurOverlay, withHeader, withStrongBlueOverLay} =
    useContext(PopupContext);
  const isActive = useSelector(selectOverlay);

  return (
    <OverlayLayout
      isActive={isActive}
      onClick={closePopup}
      withBlurOverlay={withBlurOverlay}
      withHeader={withHeader}
      withStrongBlueOverLay={withStrongBlueOverLay}
    />
  );
};

export default Overlay;
