import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  plansData: null,
};

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans(state, action) {
      state.plansData = action.payload;
    },
  },
});

export const {setPlans} = plansSlice.actions;

export const selectPlans = (state) => state.plans.plansData;

export default plansSlice.reducer;
