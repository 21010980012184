import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  wireTransfer: {
    isFetched: false,
    data: null,
  },
  payPal: {
    isFetched: false,
    data: null,
  },
  withdrawRequest: {
    data: null,
    isFetched: false,
  },
  wallet: {
    data: null,
    isFetched: false,
  },
};

const withdrawDataSlice = createSlice({
  name: 'withdrawData',
  initialState,
  reducers: {
    setWireTransferData(state, action) {
      state.wireTransfer = action.payload;
    },
    setPayPalData(state, action) {
      state.payPal = action.payload;
    },
    setWalletData(state, action) {
      state.wallet = action.payload;
    },
    setWithdrawRequestData(state, action) {
      state.withdrawRequest = action.payload;
    },
  },
});

export const {
  setPayPalData,
  setWireTransferData,
  setWalletData,
  setWithdrawRequestData,
} = withdrawDataSlice.actions;

export const selectWithdrawData = (state) => state.withdrawData;

export default withdrawDataSlice.reducer;
