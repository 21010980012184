import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  totalAmount: null,
  certificatesData: null,
  currentPage: 0,
};

const myCertificatesSlice = createSlice({
  name: 'myCertificates',
  initialState,
  reducers: {
    setMyCertificatesData(state, action) {
      state.certificatesData = action.payload.certificatesData;
      state.totalAmount = action.payload.totalAmount;
      state.currentPage = action.payload.currentPage;
    },
  },
});

export const {setMyCertificatesData} = myCertificatesSlice.actions;

export const selectMyCertificatesData = (state) => state.myCertificates;

export default myCertificatesSlice.reducer;
