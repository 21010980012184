import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  linksData: [],
  attachmentsData: [],
  areLinksFetched: false,
  areAttachmentsFetched: false,
};

const clinicPublicationsSlice = createSlice({
  name: 'clinicPublications',
  initialState,
  reducers: {
    setClinicLinksData(state, action) {
      state.linksData = action.payload.linksData;
      state.areLinksFetched = action.payload.areLinksFetched;
    },
    setClinicAttachmentsData(state, action) {
      state.attachmentsData = action.payload.attachmentsData;
      state.areAttachmentsFetched = action.payload.areAttachmentsFetched;
    },
  },
});

export const {setClinicLinksData, setClinicAttachmentsData} =
  clinicPublicationsSlice.actions;

export const selectClinicPublicationsData = (state) => state.clinicPublications;

export default clinicPublicationsSlice.reducer;
