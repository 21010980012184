import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  billing_profile: null,
  subscription: null,
  subscription_payments: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionData(state, action) {
      return action.payload;
    },
    resetSubscriptionData() {
      return initialState;
    },
  },
});

export const {setSubscriptionData, resetSubscriptionData} =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
