// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import {ENVIRONMENTS} from '@/src/app/constants/environments';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const currentEnvironment = process.env.NEXT_PUBLIC_ENVIRONMENT;

if (
  currentEnvironment === ENVIRONMENTS.PRODUCTION ||
  currentEnvironment === ENVIRONMENTS.STAGE
) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://037a50f792444a5ebc42bdfdd8d80b6b@o1076888.ingest.sentry.io/6079221',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
