import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  employees: [],
  meta: null,
  isLoading: false,
};

const clinicEmployeesSlice = createSlice({
  name: 'clinicEmployees',
  initialState,
  reducers: {
    setClinicEmployees(state, action) {
      state.employees = action.payload.data;
      state.meta = action.payload.meta;
    },
    setClinicEmployeesLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const {setClinicEmployees, setClinicEmployeesLoading} =
  clinicEmployeesSlice.actions;

export const selectClinicEmployees = (state) => state.clinicEmployees;

export default clinicEmployeesSlice.reducer;
