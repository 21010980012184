import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  contentCreator: {},
  courses: [],
};

const courseDetailsSlice = createSlice({
  name: 'contentCreatorWidget',
  initialState,
  reducers: {
    setContentCreatorCourses(state, action) {
      state.courses = action.payload;
    },
    setContentCreator(state, action) {
      state.contentCreator = action.payload;
    },
    setIsContentCreatorFollowed(state, action) {
      state.contentCreator.isFollowed = action.payload;
    },
  },
});

export const {
  setContentCreator,
  setContentCreatorCourses,
  setIsContentCreatorFollowed,
} = courseDetailsSlice.actions;

export const selectContentCreatorWidget = (state) => state.contentCreatorWidget;

export default courseDetailsSlice.reducer;
