import {createSlice} from '@reduxjs/toolkit';

import {LOCALES} from '../../constants/locales';
import {ROLES_NAMES} from '../../constants/roles';

const initialState = {
  isLoading: false,
  user: {
    id: null,
    email: '',
    type: ROLES_NAMES.STUDENT,
    first_name: '',
    last_name: '',
    username: '',
    skills: [],
  },
  permissions: {
    accessDeleteCourse: false,
    accessCreateCourse: false,
    accessUpdateCourse: false,
    accessCreatorStudioCourseList: false,
    accessWatchCourse: false,
    accessCreatePosition: false,
  },
  profile: {
    id: null,
    biography: '',
    job_title: '',
    university_name: '',
    academic_title: '',
    language: LOCALES.DE,
  },
  highlighted_surgeries: [],
  surgeries: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDataAction(state, action) {
      return action.payload;
    },
    setLoadingUser(state, action) {
      state.isLoading = action.payload;
    },
    unsetUserDataAction() {
      return initialState;
    },
    changeLocalAction(state, action) {
      state.profile.language = action.payload;
    },
    setUserBiography(state, action) {
      state.profile.biography = action.payload;
    },
    setIsMarketingSubscriptionActive(state, action) {
      state.profile.is_marketing_subscribe = action.payload;
    },
    setUserSurgeries(state, action) {
      state.surgeries = action.payload;
    },
    setUserHighlightedSurgeries(state, action) {
      state.highlighted_surgeries = action.payload;
    },
    setUserSkills(state, action) {
      state.profile.skills = action.payload;
    },
    setUserSignature(state, action) {
      state.has_signature = true;
      state.signature = action.payload;
    },
    deleteSignatureAction(state) {
      state.has_signature = false;
      state.signature = '';
    },
  },
});

export const {
  setUserDataAction,
  unsetUserDataAction,
  changeLocalAction,
  setUserBiography,
  setUserSurgeries,
  setUserHighlightedSurgeries,
  setUserSkills,
  setIsMarketingSubscriptionActive,
  setUserSignature,
  deleteSignatureAction,
  setLoadingUser,
} = userSlice.actions;
export default userSlice.reducer;
