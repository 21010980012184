import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  skillsDictionary: null,
};

const skillsDictionarySlice = createSlice({
  name: 'skillsDictionary',
  initialState,
  reducers: {
    setSkillsDictionary(state, action) {
      state.skillsDictionary = action.payload;
    },
    setSkillsDictionaryFetchingStatus(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const {setSkillsDictionary, setSkillsDictionaryFetchingStatus} =
  skillsDictionarySlice.actions;

export const selectSkillsDictionarySlice = (state) => state.skillsDictionary;

export default skillsDictionarySlice.reducer;
