import {createSlice, current} from '@reduxjs/toolkit';

const initialState = {
  reviews: {
    data: [],
    totalAmount: null,
  },
};

const courseDetailsSlice = createSlice({
  name: 'reviewsWidget',
  initialState,
  reducers: {
    setReviews(state, {payload: {data, totalAmount}}) {
      state.reviews = {
        data,
        totalAmount,
      };
    },
    addReview(state, action) {
      const newState = [...action.payload.data, ...current(state.reviews.data)];
      state.reviews = {
        data: newState.reduce((previous, current) => {
          if (previous.some((review) => review.id === current.id)) {
            return previous;
          } else {
            return [...previous, current];
          }
        }, []),
        totalAmount: action.payload.totalAmount,
      };
    },
    resetReviews(state) {
      state.reviews = initialState.reviews;
    },
  },
});

export const {setReviews, resetReviews, addReview} = courseDetailsSlice.actions;

export const selectReviewsWidget = (state) => state.reviewsWidget;

export default courseDetailsSlice.reducer;
