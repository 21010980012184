export const REST_METHODS = {
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

export const RESPONSE_STATUSES = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  UNAUTHORIZED_STATUS: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export const RESPONSE_TYPES = {
  BLOB: 'blob',
  JSON: 'json',
};
