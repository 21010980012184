import {useContext} from 'react';

import RouteContext from '@/src/routes/utils/RouteContext';

const useRoutePaths = () => {
  return useContext<{
    [key: string]: {
      path: string;
      getLink: (...params: string[]) => string;
      getLocalizationLink: (params: string) => string;
    };
  }>(RouteContext);
};

export default useRoutePaths;
