class Route {
  constructor(path, paths) {
    this.path = path;
    this.paths = paths;
  }

  prepareLink(path, ...slugs) {
    const pathToArray = path.split('/');
    let slugIndex = 0;

    const combinedPathsAndSlugs = pathToArray.map((element) => {
      let link;

      if (element.includes(':')) {
        link = slugs[slugIndex];
        slugIndex++;
      } else {
        link = element;
      }

      return link;
    });

    return combinedPathsAndSlugs.join('/');
  }

  getLocalizationLink(local, ...slugs) {
    return this.prepareLink(this.paths[local], ...slugs);
  }

  getLink(...slugs) {
    return this.prepareLink(this.path, ...slugs);
  }
}

const getPreparedLocalPaths = (routes, local) => {
  return Object.entries(routes).reduce((previous, [key, value]) => {
    return {
      ...previous,
      [key]: new Route(value[local], value),
    };
  }, {});
};

const ROUTES = {
  INDEX_ROUTE: {
    destination: '/', // page component to apply
    en: '/', // name for EN url
    de: '/', // name for DE url
  },
  NOT_FOUND_ROUTE: {
    destination: '/404', // page component to apply
    en: '/404', // name for EN url
    de: '/404', // name for DE url
  },
  INTERNAL_ERROR_ROUTE: {
    destination: '/500', // page component to apply
    en: '/500', // name for EN url
    de: '/500', // name for DE url
  },
  USER_IS_DEACTIVATED: {
    destination: '/user-is-deactivated', // page component to apply
    en: '/user-is-deactivated', // name for EN url
    de: '/benutzer-deaktiviert', // name for DE url
  },
  SIGN_IN_ROUTE: {
    destination: '/sign-in', // page component to apply
    en: '/log-in', // name for EN url
    de: '/anmelden', // name for DE url
  },
  RESEND_EMAIL_ROUTE: {
    destination: '/resend-email', // page component to apply
    en: '/resend-email', // name for EN url
    de: '/e-mail-erneut-senden', // name for DE url
  },
  FORGOT_PASSWORD_ROUTE: {
    destination: '/forgot-password', // page component to apply
    en: '/forgot-password', // name for EN url
    de: '/passwort-vergessen', // name for DE url
  },
  FORGOT_PASSWORD_PENDING_ROUTE: {
    destination: '/forgot-password/pending', // page component to apply
    en: '/forgot-password/pending', // name for EN url
    de: '/passwort-vergessen/ausstehend', // name for DE url
  },
  ROLE_APPROVAL: {
    destination: '/sign-up/role-approval', // page component to apply
    en: '/sign-up/role-approval', // name for EN url
    de: '/registrierung/rolle', // name for DE url
  },
  EXPIRED_CONFIRM_REGISTRATION_TOKEN_ROUTE: {
    destination: '/sign-up/role-approval/expired-token', // page component to apply
    en: '/sign-up/role-approval/expired-token', // name for EN url
    de: '/registrierung/rolle/abgelaufener-token', // name for DE url
  },
  SELECT_ROLE_AFTER_APPROVE: {
    destination: '/sign-up/role-approval/select-role', // page component to apply
    en: '/sign-up/role-approval/select-role', // name for EN url
    de: '/registrierung/rolle/rolle-auswahlen', // name for DE url
  },
  SIGN_UP_ROUTE: {
    destination: '/sign-up', // page component to apply
    en: '/sign-up', // name for EN url
    de: '/registrierung', // name for DE url
  },
  SIGN_UP_NEED_APPROVE_STEP_ROUTE: {
    destination: '/sign-up/need-approve', // page component to apply
    en: '/sign-up/need-approve', // name for EN url
    de: '/registrierung/brauchen-genehmigen', // name for DE url
  },
  SIGN_UP_PAYMENT_DETAILS: {
    destination: '/sign-up/payment-details', // page component to apply
    en: '/sign-up/payment-details', // name for EN url
    de: '/registrierung/zahlungsdetails', // name for DE url
  },
  SIGN_UP_ADDITIONAL_INFO_ROUTE: {
    destination: '/sign-up/additional-info', // page component to apply
    en: '/sign-up/additional-info', // name for EN url
    de: '/registrierung/zusatzliche-informationen', // name for DE url
  },
  SIGN_UP_ROUTE_USER: {
    destination: '/sign-up/:userRole', // page component to apply
    en: '/sign-up/:userRole', // name for EN url
    de: '/registrierung/:userRole', // name for DE url
  },
  MY_PROFILE_ABOUT_ME: {
    destination: '/my-profile/about-me', // page component to apply
    en: '/my-profile/about-me', // name for EN url
    de: '/mein-profil/uber-mich', // name for DE url
  },
  MY_PROFILE_MY_CERTIFICATES: {
    destination: '/my-profile/my-certificates', // page component to apply
    en: '/my-profile/my-certificates', // name for EN url
    de: '/mein-profil/meine-zertifikate', // name for DE url
  },
  MY_PROFILE_SUBSCRIPTION: {
    destination: '/my-profile/subscription', // page component to apply
    en: '/my-profile/subscription', // name for EN url
    de: '/mein-profil/abonnement', // name for DE url
  },
  MY_PROFILE_WITHDRAW: {
    destination: '/my-profile/withdraw', // page component to apply
    en: '/my-profile/withdraw', // name for EN url
    de: '/mein-profil/auszahlungen', // name for DE url
  },
  MY_PROFILE_ACCOUNT_SETTINGS: {
    destination: '/my-profile/account-settings', // page component to apply
    en: '/my-profile/account-settings', // name for EN url
    de: '/my-profile/konto-einstellungen', // name for DE url
  },
  MY_PROFILE_EMPLOYEES: {
    destination: '/my-profile/employees', // page component to apply
    en: '/my-profile/employees', // name for EN url
    de: '/my-profile/angestellte', // name for DE url
  },
  PRIVACY_POLICY_ROUTE: {
    destination: '/privacypolicy', // page component to apply
    en: '/privacypolicy', // name for EN url
    de: '/datenschutz', // name for DE url
  },
  TERMS_AND_CONDITIONS_ROUTE: {
    destination: '/termsofuse', // page component to apply
    en: '/termsofuse', // name for EN url
    de: '/agb', // name for DE url
  },
  IMPRINT_ROUTE: {
    destination: '/imprint', // page component to apply
    en: '/imprint', // name for EN url
    de: '/impressum', // name for DE url
  },
  DISCOVER: {
    destination: '/discover', // page component to apply
    en: '/discover', // name for EN url
    de: '/entdecken', // name for DE url
  },
  SEARCH: {
    destination: '/search', // page component to apply
    en: '/search', // name for EN url
    de: '/suche', // name for DE url
  },
  SEARCH_COURSES: {
    destination: '/search/courses', // page component to apply
    en: '/search/courses', // name for EN url
    de: '/suche/kurse', // name for DE url
  },
  SEARCH_POSTS: {
    destination: '/search/posts', // page component to apply
    en: '/search/posts', // name for EN url
    de: '/suche/beitrage', // name for DE url
  },
  SEARCH_CLINICS: {
    destination: '/search/clinics', // page component to apply
    en: '/search/clinics', // name for EN url
    de: '/suche/kliniken', // name for DE url
  },
  SEARCH_USERS: {
    destination: '/search/users', // page component to apply
    en: '/search/users', // name for EN url
    de: '/suche/user', // name for DE url
  },
  WATCHLIST_ROUTE: {
    destination: '/watchlist', // page component to apply
    en: '/watchlist', // name for EN url
    de: '/merkliste', // name for DE url
  },
  WATCHLIST_BOOKMARKED_ROUTE: {
    destination: '/watchlist/bookmarked', // page component to apply
    en: '/watchlist/bookmarked', // name for EN url
    de: '/merkliste/lesezeichen', // name for DE url
  },
  WATCHLIST_CONTINUE_ROUTE: {
    destination: '/watchlist/continue', // page component to apply
    en: '/watchlist/continue', // name for EN url
    de: '/merkliste/fortsetzen', // name for DE url
  },
  WATCHLIST_FINISHED_ROUTE: {
    destination: '/watchlist/finished', // page component to apply
    en: '/watchlist/finished', // name for EN url
    de: '/merkliste/beendet', // name for DE url
  },
  ACCOUNT_DELETION_CONFIRMED: {
    destination: '/delete-account/success', // page component to apply
    en: '/delete-account/success', // name for EN url
    de: '/konto-loschen/erfolgreich', // name for DE url
  },
  DELETE_ACCOUNT_ERROR: {
    destination: '/delete-account/error', // page component to apply
    en: '/delete-account/error', // name for EN url
    de: '/konto-loschen/fehler', // name for DE url
  },
  SEARCH_UNITED_ROUTE: {
    destination: '/search/united', // page component to apply
    en: '/search/united', // name for EN url
    de: '/suche/united', // name for DE url
  },
  SEARCH_EMPLOYEES_ROUTE: {
    destination: '/search/employees', // page component to apply
    en: '/search/employees', // name for EN url
    de: '/suche/angestellte', // name for DE url
  },
  SEARCH_ALL_CANDIDATES_ROUTE: {
    destination: '/search/all-candidates', // page component to apply
    en: '/search/all-candidates', // name for EN url
    de: '/suche/alle-angestellte', // name for DE url
  },
  SEARCH_ALL_POSITIONS_ROUTE: {
    destination: '/search/all-positions', // page component to apply
    en: '/search/all-position', // name for EN url
    de: '/suche/alle-stellen', // name for DE url
  },
  SEARCH_CLINIC_VACANCIES: {
    destination: '/search/clinic-vacancies', // page component to apply
    en: '/search/clinic-vacancies', // name for EN url
    de: '/suche/offene-stellen-in-der-klinik', // name for DE url
  },
  SEARCH_VACANCIES: {
    destination: '/search/vacancies', // page component to apply
    en: '/search/vacancies', // name for EN url
    de: '/suche/stellenangebote', // name for DE url
  },
  COURSES_ROUTE: {
    destination: '/courses/:slug', // page component to apply
    en: '/courses/:slug', // name for EN url
    de: '/kurse/:slug', // name for DE url
  },
  WATCH_COURSE_ROUTE: {
    destination: '/watch-course/:slug', // page component to apply
    en: '/watch-course/:slug', // name for EN url
    de: '/kurs-anschauen/:slug', // name for DE url
  },
  USER_PROFILE: {
    destination: '/profile/:username', // page component to apply
    en: '/profile/:username', // name for EN url
    de: '/profil/:username', // name for DE url
  },
  SEARCH_FOLLOWERS_ROUTE: {
    destination: '/profile/:username/followers/followers', // page component to apply
    en: '/profile/:username/followers/followers', // name for EN url
    de: '/profil/:username/follower/follower', // name for DE url
  },
  SEARCH_FOLLOWINGS_ROUTE: {
    destination: '/profile/:username/followers/following', // page component to apply
    en: '/profile/:username/followers/following', // name for EN url
    de: '/profil/:username/follower/following', // name for DE url
  },
  SEARCH_FOLLOWERS_MAIN: {
    destination: '/profile/:username/followers/:slug', // page component to apply
    en: '/profile/:username/followers/:slug', // name for EN url
    de: '/profil/:username/follower/:slug', // name for DE url
  },
  CREATOR_STUDIO: {
    destination: '/creator-studio', // page component to apply
    en: '/creator-studio', // name for EN url
    de: '/schopfer-studio', // name for DE url
  },
  CREATOR_STUDIO_TRANSLATION: {
    destination: '/creator-studio/:courseId', // page component to apply
    en: '/creator-studio/:courseId', // name for EN url
    de: '/schopfer-studio/:courseId', // name for DE url
  },
  CREATOR_STUDIO_FIRST_STEP: {
    destination: '/creator-studio/:courseId/first-step', // page component to apply
    en: '/creator-studio/:courseId/first-step', // name for EN url
    de: '/schopfer-studio/:courseId/erster-schritt', // name for DE url
  },
  CREATOR_STUDIO_SECOND_STEP: {
    destination: '/creator-studio/:courseId/second-step', // page component to apply
    en: '/creator-studio/:courseId/second-step', // name for EN url
    de: '/schopfer-studio/:courseId/zweiter-schritt', // name for DE url
  },
  CREATOR_STUDIO_THIRD_STEP: {
    destination: '/creator-studio/:courseId/third-step', // page component to apply
    en: '/creator-studio/:courseId/third-step', // name for EN url
    de: '/schopfer-studio/:courseId/dritter-schritt', // name for DE url
  },
  CREATOR_STUDIO_FOURTH_STEP: {
    destination: '/creator-studio/:courseId/fourth-step', // page component to apply
    en: '/creator-studio/:courseId/fourth-step', // name for EN url
    de: '/schopfer-studio/:courseId/vierter-schritt', // name for DE url
  },
  MARKETING_CONFIRMATION: {
    destination: '/marketing-confirmation/:type/:confirmationToken', // page component to apply
    en: '/marketing-confirmation/:type/:confirmationToken', // name for EN url
    de: '/marketing-bestatigung/:type/:confirmationToken', // name for DE url
  },
  HOW_T0: {
    destination: '/wiki-how', // page component to apply
    en: '/wiki-how', // name for EN url
    de: '/wiki-how', // name for DE url
  },
  HOW_T0_RESULT: {
    destination: '/wiki-how/result', // page component to apply
    en: '/wiki-how/result', // name for EN url
    de: '/wiki-how/result', // name for DE url
  },
  HOW_T0_SLUG: {
    destination: '/wiki-how/:slug*', // page component to apply
    en: '/wiki-how/:slug*', // name for EN url
    de: '/wiki-how/:slug*', // name for DE url
  },
  GLOSSARY: {
    destination: '/glossary', // page component to apply
    en: '/glossary', // name for EN url
    de: '/glossary', // name for DE url
  },
  GLOSSARY_RESULT: {
    destination: '/glossary/result', // page component to apply
    en: '/glossary/result', // name for EN url
    de: '/glossary/result', // name for DE url
  },
  GLOSSARY_SLUG: {
    destination: '/glossary/:slug*', // page component to apply
    en: '/glossary/:slug*', // name for EN url
    de: '/glossary/:slug*', // name for DE url
  },
  CLINIC_TARGET_PROFILE: {
    destination: '/clinic/:username', // page component to apply
    en: '/clinic/:username', // name for EN url
    de: '/klinik/:username', // name for DE url
  },
  CLINIC_SEARCH_FOLLOWERS_ROUTE: {
    destination: '/clinic/:username/followers/followers', // page component to apply
    en: '/clinic/:username/followers/followers', // name for EN url
    de: '/klinik/:username/follower/follower', // name for DE url
  },
  CLINIC_SEARCH_FOLLOWINGS_ROUTE: {
    destination: '/clinic/:username/followers/following', // page component to apply
    en: '/clinic/:username/followers/following', // name for EN url
    de: '/klinik/:username/follower/following', // name for DE url
  },
  CLINIC_SEARCH_FOLLOWERS_MAIN: {
    destination: '/clinic/:username/followers/:slug', // page component to apply
    en: '/clinic/:username/followers/:slug', // name for EN url
    de: '/klinik/:username/follower/:slug', // name for DE url
  },
  JOB_PORTAL: {
    destination: '/job-portal', // page component to apply
    en: '/job-portal', // name for EN url
    de: '/jobportal', // name for DE url
  },
  JOB_PORTAL_MY_POSITIONS: {
    destination: '/job-portal/my-positions', // page component to apply
    en: '/job-portal/my-positions', // name for EN url
    de: '/jobportal/meine-stellen', // name for DE url
  },
  JOB_PORTAL_MY_POSITION_EDIT: {
    destination: '/job-portal/my-positions/:slug/edit', // page component to apply
    en: '/job-portal/my-positions/:slug/edit', // name for EN url
    de: '/jobportal/my-positions/:slug/bearbeiten', // name for DE url
  },
  JOB_PORTAL_APPLICANTS_PAGE: {
    destination: '/job-portal/applicants/:slug', // page component to apply
    en: '/job-portal/applicants/:slug', // name for EN url
    de: '/jobportal/bewerberinnen/:slug', // name for DE url
  },
  JOB_PORTAL_MY_POSITION_PREVIEW: {
    destination: '/job-portal/my-positions/:slug', // page component to apply
    en: '/job-portal/my-positions/:slug', // name for EN url
    de: '/jobportal/my-positions/:slug', // name for DE url
  },
  JOB_PORTAL_TARGET_POSITION: {
    destination: '/job-portal/position/:slug', // page component to apply
    en: '/job-portal/position/:slug', // name for EN url
    de: '/jobportal/position/:slug', // name for DE url
  },
  JOB_PORTAL_MY_APPLIES: {
    destination: '/job-portal/my-applies', // page component to apply
    en: '/job-portal/my-applies', // name for EN url
    de: '/jobportal/mein-trifft-zu', // name for DE url
  },
  ADVISER_BOARD: {
    destination: '/advisors/advisory-board', // page component to apply
    en: '/advisors/advisory-board', // name for EN url
    de: '/advisors/advisory-board', // name for DE url
  },
};

module.exports = {
  ROUTES,
  Route,
  getPreparedLocalPaths,
};
