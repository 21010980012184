import {createContext} from 'react';

export const PopupContext = createContext({
  openPopup: () => {},
  closePopup: () => {},
  withBlurOverlay: false,
  withStrongBlueOverLay: false,
  withHeader: false,
});

export const {Consumer: PopupConsumer, Provider: PopupProvider} = PopupContext;

export default PopupContext;
