import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  originalCourses: [],
  categories: {
    categoriesData: [],
    categoryCourses: [],
  },
  randomCourses: [],
  popularCourses: [],
  searchWords: [],
  recentlyAddedCourses: [],
  medicalAdvisory: [],
  topContentCreators: [],
};

const promoSectionsSlice = createSlice({
  name: 'promoSections',
  initialState,
  reducers: {
    setOriginalCourses(state, action) {
      state.originalCourses = action.payload;
    },
    setCategories(state, action) {
      state.categories.categoriesData = action.payload;
    },
    setCategoryCourses(state, action) {
      state.categories.categoryCourses = action.payload;
    },
    setRandomCourses(state, action) {
      state.randomCourses = action.payload;
    },
    setPopularCourses(state, action) {
      state.popularCourses = action.payload;
    },
    setSearchWords(state, action) {
      state.searchWords = action.payload;
    },
    setRecentlyAddedCourses(state, action) {
      state.recentlyAddedCourses = action.payload;
    },
    setMedicalAdvisory(state, action) {
      state.medicalAdvisory = action.payload;
    },
    setTopContentCreators(state, action) {
      state.topContentCreators = action.payload;
    },
  },
});

export const {
  setOriginalCourses,
  setCategories,
  setCategoryCourses,
  setRandomCourses,
  setPopularCourses,
  setSearchWords,
  setRecentlyAddedCourses,
  setMedicalAdvisory,
  setTopContentCreators,
} = promoSectionsSlice.actions;

export const selectPromoSections = (state) => state.promoSections;
export const selectOriginalCourses = (state) =>
  state.promoSections.originalCourses;
export const selectRandomCourses = (state) => state.promoSections.randomCourses;
export const selectCategories = (state) => state.promoSections.categories;
export const selectPopularCourses = (state) =>
  state.promoSections.popularCourses;
export const selectSearchWords = (state) => state.promoSections.searchWords;
export const selectRecentlyAddedCourses = (state) =>
  state.promoSections.recentlyAddedCourses;
export const selectMedicalAdvisory = (state) =>
  state.promoSections.medicalAdvisory;
export const selectTopContentCreators = (state) =>
  state.promoSections.topContentCreators;

export default promoSectionsSlice.reducer;
