import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  reasons: null,
};

const reportReasonsSlice = createSlice({
  name: 'reportReasons',
  initialState,
  reducers: {
    setReportReasons(state, action) {
      state.reasons = action.payload;
    },
  },
});

export const {setReportReasons} = reportReasonsSlice.actions;

export const selectReportReasons = (state) => state.reportReasons.reasons;

export default reportReasonsSlice.reducer;
