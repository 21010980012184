import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  courses: [],
};

const watchedCoursesSlice = createSlice({
  name: 'watchedCourses',
  initialState,
  reducers: {
    setWatchedCourses(state, action) {
      state.courses = action.payload;
    },
  },
});

export const {setWatchedCourses} = watchedCoursesSlice.actions;

export const selectWatchedCourses = (state) => state.watchedCourses.courses;

export default watchedCoursesSlice.reducer;
