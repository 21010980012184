import {i18n} from 'next-i18next';
import {pathOr} from 'ramda';

import {PATHS} from '@/src/routes/containers/Routes';

import {RESPONSE_STATUSES, REST_METHODS} from '../constants/api';
import {store} from '../store';
import {
  getAuthorizationToken,
  removeAuthorizationToken,
} from './authorizationToken';
import getRootUrl from './getRootServerUrl';

const authorizedFetch = async ({
  method = REST_METHODS.POST,
  body,
  path,
  queryParams = [],
  formData,
  serverContext,
  headers = {},
  shouldHandleNotFound = true,
  shouldSkipUnauthorizedStatus = false,
  signal,
}) => {
  const {SIGN_IN_ROUTE, INTERNAL_ERROR_ROUTE, NOT_FOUND_ROUTE} = PATHS;
  const {defaultLocale} = i18n;
  const authorizationToken = getAuthorizationToken(serverContext);
  const baseUrl = new URL(`${getRootUrl()}${path}`);
  const locale = pathOr(
    'de',
    ['user', 'profile', 'language'],
    store.getState()
  );

  const withLocal = locale === defaultLocale ? '' : `/${locale}`;

  queryParams.forEach((param) => {
    baseUrl.searchParams.append(param.name, param.value);
  });

  const response = await fetch(decodeURI(baseUrl.href), {
    method: method,
    headers: {
      'User-Locale': locale,
      Authorization: authorizationToken,
      ...(formData ? {} : {'Content-Type': 'application/json;charset=utf-8'}),
      ...headers,
    },
    body: formData ? formData : JSON.stringify(body),
    ...(signal ? {signal: signal} : {}),
  });

  if (
    response.status === RESPONSE_STATUSES.INTERNAL_SERVER_ERROR &&
    typeof window !== 'undefined'
  ) {
    window.location.replace(`${withLocal}${INTERNAL_ERROR_ROUTE.getLink()}`);
  } else if (
    response.status === RESPONSE_STATUSES.NOT_FOUND &&
    typeof window !== 'undefined' &&
    shouldHandleNotFound
  ) {
    window.location.replace(`${withLocal}${NOT_FOUND_ROUTE.getLink()}`);
  }

  if (
    response.status === RESPONSE_STATUSES.UNAUTHORIZED_STATUS &&
    typeof window !== 'undefined' &&
    !shouldSkipUnauthorizedStatus
  ) {
    removeAuthorizationToken();
    window.location.replace(`${withLocal}${SIGN_IN_ROUTE.getLink()}`);
  }

  let parsedResponse;

  try {
    parsedResponse = await response.json();
  } catch (error) {
    parsedResponse = null;
  }

  return {
    responseBody: parsedResponse,
    status: response.status,
  };
};
export default authorizedFetch;
