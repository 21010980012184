import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isPending: null,
};

const deleteAccountSlice = createSlice({
  name: 'deleteAccount',
  initialState,
  reducers: {
    setDeleteAccountStatus(state, action) {
      state.isPending = action.payload;
    },
  },
});

export const {setDeleteAccountStatus} = deleteAccountSlice.actions;

export const selectDeleteAccountStatus = (state) => state.deleteAccount;

export default deleteAccountSlice.reducer;
