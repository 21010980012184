export const DIFFICULTY_LEVELS = {
  ALL: 'all_levels',
  BEGINNER: 'beginner',
  ADVANCED: 'advanced',
  EXPERT: 'expert',
};

export const LANGUAGES = [
  {
    label: 'create-course.english',
    value: 'en',
  },
  {
    label: 'create-course.german',
    value: 'de',
  },
];

export const DIFFICULTY_LEVELS_WITH_LABELS = [
  {
    label: 'create-course.difficulty-level-all',
    value: 'all_levels',
  },
  {
    label: 'create-course.difficulty-level-beginner',
    value: 'beginner',
  },
  {
    label: 'create-course.difficulty-level-advanced',
    value: 'advanced',
  },
  {
    label: 'create-course.difficulty-level-expert',
    value: 'expert',
  },
];

export const COURSE_VIDEO_TEMPLATE = {
  title: '',
  video_url: '', // s3 url
  video_identificator: '',
  video_preview_image: '', // binary
  video_url_isError: null,
};

export const TOPIC_TEMPLATE = {
  isEditMode: true,
  title: '',
  description: '',
  technicalFeature: '',
  links: [],
  files: [],
  videos: [COURSE_VIDEO_TEMPLATE],
};

export const NEW_COURSE_ID = 'new';

export const VIDEO_SUPPORTED_FORMAT = ['video/mp4'];

export const PDF_TYPE = ['application/pdf'];

export const SUPPORTED_IMAGES = ['image/jpg', 'image/jpeg', 'image/png'];

export const COURSE_STATUSES = {
  DELETED: 'inactive',
};
