import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RESPONSE_STATUSES} from '@/src/app/constants/api';
import {ROLES_NAMES} from '@/src/app/constants/roles';
import {
  selectClinicData,
  setClinicData,
} from '@/src/app/store/clinic/clinicDataSlice';
import useSubscriptionActions from '@/src/app/store/subscription/useSubscriptionActions';
import useUserActions from '@/src/app/store/user/useUserActions';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import getSubscriptionData from '@/src/profile/subscription/utils/getSubscriptionData';
import fetchClinicData from '@/src/signUp/additionalInfo/utils/fetchClinicData';

const COOKIES_WIDGET = {
  BUTTON: 'cookiescript_custom_button',
  BADGE: 'cookiescript_badge',
};

const AppBootstrap = ({children}) => {
  const {locale, push, asPath} = useRouter();
  const dispatch = useDispatch();
  const {setSubscriptionDataAction, resetSubscriptionDataAction} =
    useSubscriptionActions();
  const {SELECT_ROLE_AFTER_APPROVE, PRIVACY_POLICY_ROUTE} = useRoutePaths();

  const {requestUserData, user: userData} = useUserActions();
  const clinicData = useSelector(selectClinicData);

  const openCookiesWidget = () => {
    const widget = document.getElementById(COOKIES_WIDGET.BADGE);

    if (widget) {
      widget.click();
    }
  };

  useEffect(() => {
    const linkToWidget = document.getElementById(COOKIES_WIDGET.BUTTON);

    if (asPath === PRIVACY_POLICY_ROUTE.getLink() && linkToWidget) {
      linkToWidget.addEventListener('click', openCookiesWidget);

      return () => {
        if (linkToWidget) {
          linkToWidget.removeEventListener('click', openCookiesWidget);
        }
      };
    }
  }, [locale, asPath]);

  useEffect(() => {
    if (getAuthorizationToken() && !userData.user.id) {
      requestUserData();
    }
  }, []);

  useEffect(() => {
    if (userData.user.id === null && clinicData.isFetched) {
      return null;
    }

    const fetchClinic = async () => {
      const {status, responseBody} = await fetchClinicData();

      if (status === RESPONSE_STATUSES.SUCCESS) {
        dispatch(
          setClinicData({
            clinicData: responseBody,
            isFetched: true,
          })
        );
      }

      if (
        status === RESPONSE_STATUSES.UNPROCESSABLE &&
        asPath !== SELECT_ROLE_AFTER_APPROVE.getLink()
      ) {
        push(SELECT_ROLE_AFTER_APPROVE.getLink());
      }
    };

    if (
      userData.user.type &&
      userData.user.type === ROLES_NAMES.CLINIC &&
      !clinicData.isFetched
    ) {
      fetchClinic();
    }
  }, [userData, clinicData]);

  useEffect(() => {
    if (userData?.user?.id) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        user_id: userData.user.id,
      });

      const fetchSubscription = async () => {
        const {status, responseBody} = await getSubscriptionData();

        if (status === RESPONSE_STATUSES.SUCCESS) {
          setSubscriptionDataAction(responseBody);
        }
      };

      fetchSubscription();
    }

    return () => {
      resetSubscriptionDataAction();
    };
  }, [userData?.user?.id]);

  return children;
};

export default AppBootstrap;

AppBootstrap.propTypes = {
  children: PropTypes.node.isRequired,
};
